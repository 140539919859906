import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "./datatables.scss"

// Table data



function DatatableTables() {
  useEffect( () => {
      fetchproducts();
  }, []);

  const [products, setProducts] = useState([]);

  const fetchproducts = async () => {
      const data = await fetch('https://pavapp.herokuapp.com/notifies',{      method: "GET",
      credentials: "include"})

        const products = await data.json();
        setProducts(products);    ;
     



  };

 
const object={

page: 1,
sizePerPage: 10,
productData: products
}

   






const columns = [{
  dataField: "noteid",
  text: 'Sequence',
  hidden: true,

}, {
  dataField: 'notehead',
  text: 'Notification Title',
  sort: true
}, {
  dataField: 'notebody',
  text: 'Notification Detail',
  sort: true
}, {
  dataField: 'date',
  text: 'Date',
  sort: true
}, {
  dataField: 'time',
  text: 'Time',
  sort: true
}];

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

const pageOptions = {
  sizePerPage: 10,
  totalSize: products.length, // replace later with size(customers),
  custom: true,
}


const { SearchBar } = Search;
const  breadcrumbItems= [
  { title: "Pavilion Erbil", link: "#" },
  { title: "Notifications", link: "#" },
]

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Notifications </CardTitle>
                    <p className="card-title-desc">
                     These is the Global Notifiactions from pavilion Erbil Administration and Sales office for all property owners
                    </p>

                
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='noteid'
                      columns={columns}
                      data={object.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='noteid'
                          columns={columns}
                          data={object.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap "
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-products-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  
}

export default DatatableTables