import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Card, Form } from "reactstrap";
import { Link } from "react-router-dom";

//Import Editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Import Images

class EmailSidebars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
        this.togglemodal.bind(this);
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {

        return (
            <React.Fragment>
                {/* left sidebar start */}
                <Card className="email-leftbar">
                    <Button type="button" color="danger" onClick={this.togglemodal} block className="waves-effect waves-light" >
                        Compose
                                    </Button>
                    <div className="mail-list mt-4">
                        <Link to={`/email-inbox/${this.props.props.ids}`} ><i className="mdi mdi-email-outline me-2"></i> Inbox <span className="ms-1 float-end"></span></Link>
                        <Link to={`/email-inbox/${this.props.props.ids}`} className="active"><i className="mdi mdi-email-check-outline me-2"></i>Sent Mail</Link>
                    </div>


                
                </Card>
                {/* left sidebar over */}
                <Modal isOpen={this.state.modal} role="document" autoFocus={true} centered={true} className="composemodal" tabIndex="-1" toggle={this.togglemodal}>
                    <div className="modal-content">
                        <ModalHeader toggle={this.togglemodal}>
                            New Message
                            </ModalHeader>
                        <ModalBody>
                            <Form action="https://pavapp.herokuapp.com/send-m" method="post" >
                                <div className="mb-3">
                                <Input type="number" className="form-control hidden" name="ids" defaultValue={this.props.props.ids} placeholder="ID" />

                                    <Input type="select" className="form-control" name="toto" placeholder="To" >
                                    <option value="Pavilion Erbil Sales Office">Pavilion Erbil Sales Office</option>

            <option value="Pavilion Erbil Support Desk">Pavilion Erbil Support Desk</option>
            <option value="Pavilion Erbil Legal Affairs">Legal Affairs</option>

          </Input>

                                </div>

                                <div className="mb-3">
                                    <Input type="text" className="form-control" placeholder="Subject" name="subject" />
                                </div>
                                <div className="mb-3">
                                  <Input type="textarea" className="form-control" placeholder="Your Message" name="mbodysent"></Input>
                                </div>
                                <ModalFooter>
                            <Button coloe="secondary" type="button" onClick={this.togglemodal} >Close</Button>
                            <Button type="submit" color="primary">Send <i className="fab fa-telegram-plane ms-1"></i></Button>
                        </ModalFooter>
                            </Form>
                        </ModalBody>
                   
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default EmailSidebars;