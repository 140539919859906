import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Authentication/Login";


import Loginfaild from "../pages/Authentication/Loginfail";

// Dashboard
import Dashboard from "../pages/Dashboard/index";


import Propdetail from "../pages/Ecommerce/EcommerceProducts/PropDetail"

//Email
import EmailIsent from "../pages/Email/email-isent";
import EmailInbox from "../pages/Email/email-inbox";

import EmailRead from "../pages/Email/email-read";
import EmailReadSent from "../pages/Email/email-read-isent";


//Utility
import NewsLoad from "../pages/Utility/StarterPage";

import NewsDetail from "../pages/Utility/NewsDetail";

import Maintenance from "../pages/Utility/Maintenance";
import Timeline from "../pages/Utility/Timeline";

import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";




//Tables
import Notstable from "../pages/Tables/NotesTableAll";


//Kanban Board

const authProtectedRoutes = [

	//Kanban Board
	


	{ path: "/nottable/:id", component: Notstable },




	//Utility
	{ path: "/news/:id", component: NewsLoad },
	{ path: "/news-detail/:id", component: NewsDetail },

	{ path: "/events/:id", component: Timeline },


	

	//Email
	{ path: "/email-inbox/:id", component: EmailInbox },
	{ path: "/email-isent/:id", component: EmailIsent },

	{ path: "/email-read/:id", component: EmailRead },
	{ path: "/email-isent-read/:id", component: EmailReadSent },


	//Ecommerce
	
	{ path: "/prop-detail/:id", component: Propdetail },



	{ path: "/dashboard/:id", component: Dashboard },

	// this route should be at the end of all other routes
];

const publicRoutes = [
	{ path: "/login", component: Login },


	{ path: "/login-fail", component: Loginfaild },



	// Authentication Inner


	{ path: "/maintenance", component: Maintenance },
	{ path: "/404", component: Error404 },
	{ path: "/500", component: Error500 },



];

const Homees=[
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> },

]
export { authProtectedRoutes, publicRoutes,Homees };
