import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";


class NotificationDr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            notifiS: [],

            
        };
        
    }


    
    componentDidMount() {

   
       fetch("https://pavapp.herokuapp.com/notify",{      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(notiflist => {
           this.setState({ notifiS: notiflist });

       });
   }



   render() {

        return (
            <React.Fragment>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Dropdown className="float-end" >
                                <DropdownToggle tag="i" className="darrow-none card-drop" aria-expanded="false">
                                    <i className="mdi mdi-dots-vertical"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">

                                    <DropdownItem href=""> Report</DropdownItem>

                                </DropdownMenu>
                            </Dropdown>

                            <h4 className="card-title mb-4">Recent Notifiactions Feed</h4>

                            <SimpleBar style={{ maxHeight: "330px" }}>
                                <ul className="list-unstyled activity-wid">
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                <i className={this.state.notifiS[0]?.icon}></i>
                                            </span>
                                        </div>
                                        <div>


                                            <div>
                                                <h5 className="font-size-13">{this.state.notifiS[0]?.date}<small className="text-muted"> {this.state.notifiS[0]?.time}</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">{this.state.notifiS[0]?.notehead}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                <i className={this.state.notifiS[1]?.icon}></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13"> {this.state.notifiS[1]?.date}<small className="text-muted"> {this.state.notifiS[1]?.time}</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">{this.state.notifiS[1]?.notehead}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                <i className={this.state.notifiS[2]?.icon}></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">{this.state.notifiS[2]?.date} <small className="text-muted"> {this.state.notifiS[2]?.time}</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">{this.state.notifiS[2]?.notehead}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="activity-list">
                                        <div className="activity-icon avatar-xs">
                                            <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                <i className={this.state.notifiS[3]?.icon}></i>
                                            </span>
                                        </div>
                                        <div>
                                            <div>
                                                <h5 className="font-size-13">{this.state.notifiS[3]?.date} <small className="text-muted"> {this.state.notifiS[3]?.time}</small></h5>
                                            </div>

                                            <div>
                                                <p className="text-muted mb-0">{this.state.notifiS[3]?.notehead}</p>
                                            </div>
                                        </div>
                                    </li>
                                  
                                </ul>
                            </SimpleBar>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    
}
}

export default NotificationDr;