import React, { Component } from 'react';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Input,
    TabPane,
    Table,
  } from "reactstrap";
  import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';








class StarterPage extends Component {

    
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Pavilion Erbil", link : "/" },
                { title : "News", link : "#" },
            ],

            newsdeep: [],
            neid:{}
        }
    }


    componentDidMount() {

       
 
        const newspath = this.props.location.state;
      
         const uri=`https://pavapp.herokuapp.com/news-list/${newspath}`;
    
        fetch(uri,{      method: "GET",
        credentials: "include",
        
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
    
          "Access-Control-Allow-Credentials": true,
    
        },})
        .then((response) => response.json())
        .then(newsdeepist => {
            this.setState({ newsdeep: newsdeepist });
        });
    }
    
      

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Pavilion Erbil News" breadcrumbItems={this.state.breadcrumbItems} />
                    
  <React.Fragment>
                <Row>
                  <Col lg={12}>
                  <Card >
                      <CardBody>
                        <Row>        
          {
            this.state.newsdeep.map((item,i) =>            

           

            <Col  key={i} xl="12">
                          <div className='news-header'>
                                <h4 className=" card-title mb-4">{item.newshead}</h4>


                                </div>
                                
                                <div className='news-header-divider'>
                                <hr className="my-4 line-news" />

                                </div>

          

                                        <div className='mt-2 text-center news-detail-img'>

                                            <img alt=""  src={`https://pavapp.herokuapp.com/images/${item.newsimg}` } />
                                        
                                            </div>


                                            <div className='news-tag-divider'>

                                     <h6><i className='ri-calendar-2-line'></i> {item.newsdate} <i className='ri-price-tag-3-line'></i> {item.newstag1} <i className='ri-price-tag-3-line'></i> {item.newstag2} <i className='ri-price-tag-3-line'></i> {item.newstag3}</h6>       
                                <hr className="my-4 line-news" />

                                </div>
                                
                                <div >

<p>{item.newspar1} 
</p>
</div>


<div >

<p>{item.newspar2} 
</p>
</div>


<div >

<p>{item.newspar3} 
</p>
</div>
                                            <div className='goback-mores'>

                                            <p><Link to={"/news"} className="">Go back to News Section {" >"} </Link></p>

                                        </div>
                                
                                    

                                        </Col>



           )
         }
                        

            

         </Row>

</CardBody>
</Card>


</Col>
</Row>
                          </React.Fragment>

                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default StarterPage;