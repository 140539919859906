import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardImg,Input, Form ,Button} from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import EmailSidebars from "./email-sidebar-sent";
import EmailToolBar from "./email-toolbar";

//Import Image
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/logo-dark.png";
import img4 from "../../assets/images/small/img-4.jpg";

class EmailRead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Messages", link: "#" },
                { title: "Read Message", link: "#" },
            ],

            emailbodyse: [],

        }
    }



     
    componentDidMount() {
        const id=window.location.pathname;

        const answer = id.split('/');
 
        const esepath = answer[2];
    

         const uri=`https://pavapp.herokuapp.com/email-body-sent/${esepath}`;
   
       fetch(uri,{      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(emailList => {
           this.setState({ emailbodyse: emailList });

       });
   }
   

    render() {


        var elength = this.state.emailbodyse.length
        var first = this.state.emailbodyse[0];

const inboxprops={ids:first?.owners_id,lenghtemail:elength}



        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Read Message" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>

                                {/* left sidebar */}
                                <EmailSidebars props={inboxprops}/>

                                <div className="email-rightbar mb-3">

                                    <Card>
                                        {/* email toolbar */}
                                        <EmailToolBar />
                                        {
            this.state.emailbodyse.map((item,i) =>      
                                        <CardBody>
                                  
                                            <div key={i} className="d-flex mb-4 emailsender">
                                                <i className="d-flex me-3 rounded-circle avatar-sm ri-shield-user-line"  alt="Generic placeholder" />
                                                <div className="flex-1">
                                                    <h5 className="font-size-14 my-1">{item.sentby}</h5>
                                                    <small className="text-muted">To: {item.to}</small>
                                                </div>
                                            </div>


                                            <div  className="d-flex mb-4">
                                                <div className="flex-1">
                                                <h4 className="mt-0 font-size-16">Subject: {item.subject}</h4>
                                                    <small className="text-muted">You Sent this Message on <strong>{item.date} - {item.time}</strong></small>
                                                </div>
                                            </div>


                                            <p>{item.ebody}</p>
                                            

                                            <hr />

                                            <Row>
                                                <Col xl={2} xs={6}>
                                                    <Card>
                                                        <CardImg top className="img-fluid" src={img3} alt="Card cap" />
                                                        <div className="py-2 text-center">
                                                        </div>
                                                    </Card>
                                                </Col>
                                          
                                            </Row>
                                            <Link to={`/email-isent/${item.owners_id}`} className="btn btn-secondary waves-effect mt-4"><i className="mdi mdi-reply"></i> Go Back</Link>
                                         
                                            
                                             </CardBody>
                                             
                                             )}


                                  
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailRead;