import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup, Modal, ModalHeader,Form, ModalBody, ModalFooter, Card, Input, Label, Container } from "reactstrap";
import { Link } from "react-router-dom";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import EmailSidebar from "./email-sidebar";
import EmailToolBar from "./email-toolbar";

class EmailInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {


            breadcrumbItems: [
                { title: "Messages", link: "#" },
                { title: "Inbox", link: "#" },
            ],

            emails: [],

            

        }


    }


 
    
    componentDidMount() {
        const id=window.location.pathname;

        const answer = id.split('/');
 
        const path = answer[2];
   

         const uri=`https://pavapp.herokuapp.com/email-list/${path}`;
   
       fetch(uri, {      method: "GET",
       credentials: "include",
       
       headers: {
         "Content-Type": "application/json",
         'Access-Control-Allow-Origin': '*',
   
         "Access-Control-Allow-Credentials": true,
   
       },})
       .then((response) => response.json())
       .then(emailList => {
           this.setState({ emails: emailList });

       });
   }
   

    render() {
        var elength = this.state.emails.length
        var first = this.state.emails[0];

const inboxprops={ids:first?.owners_id,lenghtemail:elength}

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Pavilion Erbil Express Contact" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                {/*Left sidebar  */}
                                <EmailSidebar props={inboxprops}/>


                                {/*  Right Sidebar */}
                                <div className="email-rightbar mb-3">

                                    <Card>

                                        {/* toolbar */}
                                        <EmailToolBar />

                                        <ul className="message-list">
                                        {
            this.state.emails.map((item,i) =>        
                                            <li ke={i}>
                                                <div className="col-mail col-mail-1">
                                                    <div className="checkbox-wrapper-mail">
                                                        <Input type="checkbox" id="chk19" />
                                                        <Label htmlFor="chk19" className="toggle"></Label>
                                                    </div>
                                                    <Link to={`/email-read/${item.email_id}`}  className="title">{item.sentby}</Link><span className="star-toggle far fa-star"></span>
                                                </div>
                                                <div className="col-mail col-mail-2">
                                                    <Link to={`/email-read/${item.email_id}`} className="subject">{item.subject} – <span className="teaser">{item.sliceb}...</span>
                                                    </Link>
                                                    <div  className="date">{item.date}</div>
                                                </div>

                                               
                                            </li>

                                         
                                       ) }

                                        </ul>

                                    </Card>
                                  
                                    <Row>
                                        <Col xs={7}>
                                            Showing {elength} Received Messages
                                        </Col>
                                        <Col xs={5}>
                                            <ButtonGroup className="float-end">
                                                <Button type="button" size="sm" color="success" className="waves-effect"><i className="fa fa-chevron-left"></i></Button>
                                                <Button type="button" size="sm" color="success" className="waves-effect"><i className="fa fa-chevron-right"></i></Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>

                                </div>

                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailInbox;