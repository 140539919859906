import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18b
import { withNamespaces } from "react-i18next";

//Import images


class NotificationDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            notifi: [],

            
        };
        this.toggle = this.toggle.bind(this);
        
    }


    
    componentDidMount() {

   
       fetch("https://pavapp.herokuapp.com/notify",{method: "GET",
       credentials: "include",
       headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
  
        "Access-Control-Allow-Credentials": true,
  
      }})
       .then((response) => response.json())
       .then(notiflist => {
           this.setState({ notifi: notiflist });

       });
   }
   


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }
    render() {


        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
                    <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown">
                        <i className="ri-notification-3-line"></i>
                        <span className="noti-dot"></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg p-0"
                        aria-labelledby="page-header-notifications-dropdown">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0"> {this.props.t('Notifications')} </h6>
                                </Col>
                                <div className="col-auto">
                                </div>
                            </Row>
                        </div>
                        <SimpleBar style={{ maxHeight: "230px" }}>
                            <Link to="#" className="text-reset notification-item">
                                <div className="d-flex">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                                            <i className={this.state.notifi[0]?.icon}></i>
                                        </span>
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mt-0 mb-1">{this.state.notifi[0]?.notehead}</h6>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">{this.state.notifi[0]?.notebody}</p>
                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {this.state.notifi[0]?.date}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" className="text-reset notification-item">
                                <div className="d-flex">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                                            <i className={this.state.notifi[1]?.icon}></i>
                                        </span>
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mt-0 mb-1">{this.state.notifi[1]?.notehead}</h6>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">{this.state.notifi[1]?.notebody}</p>
                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {this.state.notifi[1]?.date}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="#" className="text-reset notification-item">
                                <div className="d-flex">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title bg-success rounded-circle font-size-16">
                                        <i className={this.state.notifi[2]?.icon}></i>
                                        </span>
                                    </div>
                                    <div className="flex-1">
                                        <h6 className="mt-0 mb-1">{this.state.notifi[2]?.notehead}</h6>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">{this.state.notifi[2]?.notebody}</p>
                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {this.state.notifi[2]?.date}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                        </SimpleBar>
                        <div className="p-2 border-top">
                            <Link to="#" className="btn btn-sm btn-link font-size-14 btn-block text-center">
                            </Link>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}
export default withNamespaces()(NotificationDropdown);
