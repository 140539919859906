import React, { Component } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonToolbar } from "reactstrap";

class EmailToolBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folder_menu: false,
            tag_menu: false,
            more_menu: false
        };
        this.toggleFolder = this.toggleFolder.bind(this);
        this.toggleTag = this.toggleTag.bind(this);
        this.toggleMore = this.toggleMore.bind(this);
    }

    toggleFolder() {
        this.setState(prevState => ({
            folder_menu: !prevState.folder_menu
        }));
    }

    toggleTag() {
        this.setState(prevState => ({
            tag_menu: !prevState.tag_menu
        }));
    }

    toggleMore() {
        this.setState(prevState => ({
            more_menu: !prevState.more_menu
        }));
    }

    render() {
        return (
            <React.Fragment>
                <ButtonToolbar className="p-3" role="toolbar">
                    <ButtonGroup className="me-2 mb-2 mb-sm-0">
                        <Button type="button" color="primary" className=" waves-light waves-effect">Your Chat history, requests, questions and notifiactions with Pavilion Erbil Sales Office</Button>
                        <Button type="button" color="primary" className="waves-light waves-effect"><i className="fa fa-exclamation-circle"></i></Button>
                    </ButtonGroup>
                 
              
                </ButtonToolbar>
            </React.Fragment>
        );
    }
}

export default EmailToolBar;