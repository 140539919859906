import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class RevenueAnalytics extends Component {


    constructor(props) {
        super(props);
     

        this.state = {
            series: [{
                name: '2023',
                type: 'column',
                data: [0,0,0,0,0]
            }, ],
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
              
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '20%',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
    
                legend: {
                    show: false,
                },
                colors: ['#0ebfe8'],
                labels: ['Structure', 'Exterior', 'Interior', 'Finishing', 'Gardens'],
            },
            dataL: {},

        }
        
    }


    
    componentDidMount() {

   

        const datid=window.location.pathname;

        const answers = datid.split('/');
 
        const prog = answers[2];
    
         var uri=`https://pavapp.herokuapp.com/progress/${prog}`;

       fetch(uri)
       .then((response) => response.json())
       .then(fdata => {

const construct=[fdata.struct,fdata.exter,fdata.inter,fdata.finishing,fdata.garden];


const stats=[fdata.struct,fdata.exter,fdata.inter,fdata.finishing,fdata.garden];

this.setState({dataL:fdata});
           this.setState({ series: [{
            name: '2023',
            type: 'column',
            data: construct
        }, ] });

       },[this.state]);
   }
   

 


    render() {


        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" active type="button">Updated On: {this.state.dataL.lastup}</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Your Property Construction Analytics</h4>
                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="280" />
               
                            </div>
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row>
                            <Col sm={4}>
                                <div className="d-inline-flex">
                                    <h5 className="me-2">Annual Progress Result is calculated in respect of all 5 Parameters</h5>
                                    <div className="text-success">
                                        <i className=""> </i>
                                                    </div>
                                </div>
                                <p className="text-muted text-truncate mb-0"></p>
                            </Col>

                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Year Progress</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{this.state.dataL.yeardecimal}%</h5>
                                        <div className="text-success">
                                            <i className="mdi mdi-menu-up font-size-14"> </i>
                                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Year Progress:</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0">{this.state.dataL.prevdecimal}%</h5>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default RevenueAnalytics;