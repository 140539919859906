import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container } from "reactstrap";

// Redux
import { Link } from 'react-router-dom';

// availity-reactstrap-validation

// actions

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

class Logfail extends Component {

    constructor(props) {
        super(props);
        this.state = { username: "", password: "" }
    }




    render() {

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                    <div>
                                                        <Link to="/" >
                                                            <img src={logodark} alt="" height="50" className="auth-logo logo-dark mx-auto" />
                                                            <img src={logolight} alt="" height="50" className="auth-logo logo-light mx-auto" />
                                                        </Link>
                                                    </div>

                                                        <h4 className="font-size-18 mt-4 failed-bar">Failed to Login</h4>
                                                        <p className="text-muted failed-bar">We don't recognize the information Your Provided Please check your propert ID and Password </p>
                                                    </div>


                                                    {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null}

                                                    <div className="p-2 mt-5">
                                                        <form className="form-horizontal" action="./login" method="post" >
                                        


                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-home-2-line auti-custom-input-icon"></i>
                                                                <label htmlFor="pr_id">Property ID</label>
                                                                <input name="uname"  type="text"  className="form-control" id="username" placeholder="Your Property ID" />
                                                            </div>

                                       
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <label htmlFor="userpassword">Password</label>
                                                                <input name="pw"  type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                            </div>

                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" id="customControlInline" />
                                                                <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Link to="https://www.pavilionerbil.com/contact" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>Difficulties loging in ? <Link to="https://www.pavilionerbil.com/contact" className="fw-medium text-primary"> Contact Sales Office </Link> </p>
                                                        <p>© 2023 Pavilion Erbil. Powered by Artin Crown Productions</p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}



export default Logfail;