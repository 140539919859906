import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Card, CardBody, Row, Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
function coure(props){


    return (

        <Card>
        <CardBody>

        <Row>
                                <Col sm={12}>
                                <div>
        <h4 className="card-title mb-4">Your Property's Latest Images</h4>

        </div>
        
    <Carousel howArrows={true} emulateTouch={true}  autoPlay={true} showThumbs={false}	 dynamicHeight={false}>
        
       
        <div>


            <img alt="" src={'https://pavapp.herokuapp.com/images/'+props.props.img1 } />
            <p className="legend key-legend">{props.props.img1d }</p>
        </div>
        <div>
            <img alt="" src={'https://pavapp.herokuapp.com/images/'+props.props.img2 }/>
            <p className="legend key-legend">{props.props.img2d }</p>
        </div>
        <div>
            <img alt="" src={'https://pavapp.herokuapp.com/images/'+props.props.img3 } />
            <p className="legend key-legend">{props.props.img3d }</p>
        </div>
     
    </Carousel>
    </Col>

</Row>

    </CardBody>
                </Card>
    )
}


export default coure;