import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Input,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { Carousel } from 'react-responsive-carousel';

import Breadcrumbs from "../../../components/Common/Breadcrumb";


import "react-responsive-carousel/lib/styles/carousel.min.css"; 

class PropDetail extends Component {


  
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      activeDescriptionTab: "description",
      product: {},
      breadcrumbItems: [
        { title: "Pavilion Erbil", link: "/" },
        { title: "Property Detail", link: "#" },
      ],

      posts: []

    };
    
    this.toggleTab = this.toggleTab.bind(this);
    this.toggledescription = this.toggledescription.bind(this);
    this.imageShow = this.imageShow.bind(this);
  }


  componentDidMount() {

    const id=window.location.pathname;

    const answer = id.split('/');

    const path = answer[2];
   
     const uri=`https://pavapp.herokuapp.com/props-list/${path}`;

    fetch(uri,{      method: "GET",
    credentials: "include",
    
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',

      "Access-Control-Allow-Credentials": true,

    },})
    .then((response) => response.json())
    .then(booksList => {
        this.setState({ posts: booksList });
    });
}

  

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggledescription(tab) {
    if (this.state.activeDescriptionTab !== tab) {
      this.setState({
        activeDescriptionTab: tab,
      });
    }
  }

  imageShow(img, id) {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = img;
  }

  render() {
    const { product } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Ecoomerce"
              breadcrumbItems={this.state.breadcrumbItems}
            />

         
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="6">
                            <div className="product-detail">
                            
                                   
                                


               
        
    <Carousel howArrows={true} emulateTouch={true}  autoPlay={true} showThumbs={true}	 dynamicHeight={false}>
        
       
        <div>


            <img alt="" src={'https://pavapp.herokuapp.com/images/'+this.state.posts.img1 } />
            <p className="legend key-legend">{this.state.posts.img1_date }</p>
        </div>
        <div>
            <img alt="" src={'https://pavapp.herokuapp.com/images/'+this.state.posts.img2 }/>
            <p className="legend key-legend">{this.state.posts.img2_date }</p>
        </div>
        <div>
            <img alt="" src={'https://pavapp.herokuapp.com/images/'+this.state.posts.img3 } />
            <p className="legend key-legend">{this.state.posts.img3_date }</p>
        </div>

        <div>
            <img alt="" src={'https://pavapp.herokuapp.com/images/'+this.state.posts.locs } />
        </div>
     
     
    </Carousel>

                        
                            </div>
                          </Col>

                          <Col lg="6">
                            <div className="mt-4 mt-xl-3">
                              <Link to="#" className="text-primary">
                              Your Property Type
                                    </Link>
                              <h5 className="mt-1 mb-3">{this.state.posts.proptype}</h5>

                              <div className="d-inline-flex">
                                <div className="text-muted me-3">
                                <p>Contract Refrence: {this.state.posts.contnumb}</p>
                         
                                </div>
                                <div className="text-muted">
                                <p>Registration Date: {this.state.posts.purchase}</p>

                                </div>
                              </div>



                              <div className="text-muted me-3">
                                <p>Property Front View: {this.state.posts.view}</p>

                                </div>

                                <div className="text-muted">

                                </div>

                              <h5 className="mt-2">
                              
                             
                          
                              </h5>

                              
                              <hr className="my-4" />

                              <Row>
                                <Col md="6">
                                  <div>
                                    <h5 className="font-size-14">
                                      <i className="mdi mdi-location"></i>
                                              Total Area: 
                                              </h5>
                                    <div className="d-flex flex-wrap">
                                      <div className="input-group mb-3 w-auto">
                                      {this.state.posts.totalarea} SQM
                                     
                                      </div>
                                    </div>

                                    <h5 className="font-size-14">
                                      Plot Area :
                                    </h5>
                                    <ul className="list-unstyled product-desc-list">
                                    {this.state.posts.plotarea} SQM
                                    </ul>
                                  </div>
                                </Col>

                                <Col md="6">
                                  <h5 className="font-size-14">Main Specifications :</h5>
                                  <ul className="list-unstyled product-desc-list">
                                        <li>
                                        <i className="mdi mdi-circle-medium me-1 align-middle"></i>{this.state.posts.spec1}
                                        </li>
                                        <li>
                                        <i className="mdi mdi-circle-medium me-1 align-middle"></i> {this.state.posts.spec2}
                                        </li>
                                        <li>
                                        <i className="mdi mdi-circle-medium me-1 align-middle"></i> {this.state.posts.spec3}
                                        </li>
                                  </ul>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="6">
                                  <div className="product-color">
                                    <h5 className="font-size-15">Construction Progress :</h5>
                                    <ul className="list-unstyled product-desc-list">
                                        <li>
                                        Structure: {this.state.posts.struct}%
                                        </li>
                                        <li>
                                        Exterior: {this.state.posts.exter}%
                                        </li>
                                        <li>
                                        Interior: {this.state.posts.inter}%
                                        </li>
                                        <li>
                                        Finishing: {this.state.posts.finishing}%
                                        </li>
                                        <li>
                                        Gardens: {this.state.posts.garden}%
                                        </li>

                                        <li>
                                        <hr className="my-4" />
                                        </li>

                                        <li>
                                        <h5 className="font-size-14">

                                        Total Construction Progreess: {this.state.posts.totalprog}%
                                        </h5>
                                        </li>
                                  </ul>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="product-color mt-3">
                                    <h5 className="font-size-14">Payment Status:</h5>
                                    <ul className="list-unstyled product-desc-list">

                                    <li>
                                        <strong>Status:</strong> {this.state.posts.paystat}
                                        </li>
                                        <li>
                                        <strong>Next Due Amount :</strong> ${this.state.posts.inst_amount}
                                        </li>
                                        <li>
                                        <strong> Due Date:</strong> {this.state.posts.inst_next}
                                        </li>
                                        <li>
                                        <strong>Last Payment:</strong> {this.state.posts.inst_last}
                                        </li>
                                     
                                   

                                  </ul>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <h5 className="font-size-14 mb-3">
                          </h5>
                          <div className="product-desc">
                            <Nav tabs className="nav-tabs-custom">
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active:
                                      this.state.activeDescriptionTab ===
                                      "description",
                                  })}
                                  onClick={() => {
                                    this.toggledescription("description");
                                  }}
                                >
                                  Description
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active:
                                      this.state.activeDescriptionTab ===
                                      "specifications",
                                  })}
                                  onClick={() => {
                                    this.toggledescription("specifications");
                                  }}
                                >
                                  Specifications
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent
                              activeTab={this.state.activeDescriptionTab}
                              className="border border-top-0 p-4"
                            >
                              <TabPane tabId="description">
                                <div>
                                  <p>
                                   {this.state.posts.propdesc}
                                  </p>
                                
                                </div>
                              </TabPane>
                              <TabPane tabId="specifications">
                                <div className="table-responsive">
                                  <Table className="table-nowrap mb-0">
                                  <p>
                                    Your Property's Main specifications are:
                                  </p>

                                  <div>
                                    <p className="mb-2">
                                      <i className="mdi mdi-circle-medium me-1 align-middle"></i>{" "}
                                    {this.state.posts.spec1}
                                    </p>
                                    <p className="mb-2">
                                      <i className="mdi mdi-circle-medium me-1 align-middle"></i>{" "}
                                      {this.state.posts.spec2}

                                    </p>
                                    <p className="mb-0">
                                      <i className="mdi mdi-circle-medium me-1 align-middle"></i>{" "}
                                      {this.state.posts.spec3}
                                    </p>

                                    <p className="mb-0">
                                      <i className="mdi mdi-circle-medium me-1 align-middle"></i>{" "}
                                      {this.state.posts.spec4}
                                    </p>
                                  </div>
                                  </Table>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                   
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
        
          </Container>
        </div>
      </React.Fragment>
    );
  }
}



export default connect(

)(PropDetail);
