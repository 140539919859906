import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import Chart from "react-apexcharts";

//Import Charts
import "./dashboard.scss";

class SalesAnalytics extends Component {


    constructor(props) {
        super(props);

    this.state = {
        series: [0,0,0,0,0],
        options: {
            labels: ["Structure","Exterior","Interior","Gardens","Finishing"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#0ebfe8', '#164B42', '#eeb902','#1cbb8c','#994700'],
           
        },

        datapr: {},

    }

    }




 
    componentDidMount() {

   

        var datid=window.location.pathname;

        var answers = datid.split('/');
 
        var prog = answers[2];
    
         var uri=`https://pavapp.herokuapp.com/progrcess/${prog}`;

       fetch(uri)
       .then((response) => response.json())
       .then(Adata => {

const progde=[Adata.struct,Adata.exter,Adata.inter,Adata.finishing,Adata.garden];



this.setState({datapr:Adata});
           this.setState({ series:progde } );

       },[this.state]);
   }
   





    render() {


        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end">
                          
                        </div>
                        <h4 className="card-title mb-4">Construction Progress</h4>

                        <div id="donut-chart" className="apex-charts">
                            <Chart options={this.state.options} series={this.state.series} type="donut" height="250" />
                        </div>

                        <Row>
                            <Col xs={4}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className=""></i></p>
                                    <h5></h5>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Total</p>
                                    <h5>{this.state.datapr.total}%</h5>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className=""></i></p>
                                    <h5></h5>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;