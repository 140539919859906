import React, { Component } from 'react';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Input,
    TabPane,
    Table,
  } from "reactstrap";
  import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';








class StarterPage extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Pavilion Erbil", link : "/" },
                { title : "News", link : "#" },
            ],

            newsl: [],
            overs: {  },

        }
    }


    componentDidMount() {

        const id=window.location.pathname;

        const answer = id.split('/');
        const routess = answer[2];
  
         const uri=`https://pavapp.herokuapp.com/news-list`;
    
        fetch(uri,{      method: "GET",
        credentials: "include",
        
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
    
          "Access-Control-Allow-Credentials": true,
    
        },})
        .then((response) => response.json())
        .then(newsList => {
            this.setState({ newsl: newsList });

            this.setState({ overs: routess });

        });
    }
    
      

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Pavilion Erbil News" breadcrumbItems={this.state.breadcrumbItems} />
                    
  <React.Fragment>
                <Row>
                  <Col lg={12}>
                  <Card >
                      <CardBody>
                        <Row>        
          {
            this.state.newsl.map((item,i) =>            

         

            <Col  key={i} xl="4">
                          <div className='news-header'>
                                <h4 className=" card-title mb-4">{item.newshead}</h4>
                                <p>{item.newsdate}</p>

                                <hr className="my-4 line-news" />

                                </div>



                                <div >

                                    <p>{item.slicepar} ...</p>
                                </div>

                                        <div >

                                            <img alt="" className='news-img' src={`https://pavapp.herokuapp.com/images/${item.newsimg}` } />
                                        
                                            </div>
                                            
                                            <div className='read-mores'>

                                            <p><Link to={{pathname:`/news-detail/${this.state.overs}`,state:`${item.idnews}`}} className="">Read More {" >"} </Link></p>
                                            
                                        </div>
                                        <div className='news-header-divider'>

                                        <hr className="my-4 line-news" />
                                        </div>


                                        </Col>



           )
         }
                        

            

         </Row>

</CardBody>
</Card>


</Col>
</Row>
                          </React.Fragment>

                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default StarterPage;